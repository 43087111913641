* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Lexend Exa', sans-serif;
  background: url('./images/bg/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  color: wheat;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

header h1 {
  margin: 50px 0px 20px 0px;
  text-align: center;
  padding: 2rem;
  background: black;
  font-size: 40px;
}
header p {
  font-size: 25px;
  color: black;
}

.textarea_00 {
  height: 500px;
  width: 100%;
}

.button_test {
  margin-bottom: 50px;
  height: 50px;
  width: 50%;
  display: block;
  margin: auto;
}

.tabth {
  color: black;
}


@media (max-width: 480px){
  header h1{
    font-size: 35px;
  }
  header p{
    font-size: 16px;
  }
}